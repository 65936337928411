import React, { useState, Fragment, useEffect } from "react"
import { InputText, Warning } from "./useInput"
import firebase from "gatsby-plugin-firebase"
import defaultProfile from "./defaultProfile"
import Loading from "./loading"

export default function Signup({ activeScreen, setActiveScreen, profile, setProfile, type ,handleDiscountBar }) {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(undefined)
  const [isNewUser, setIsNewUser] = useState(false)

  useEffect(() => {
    firebase.auth().signOut()
  }, [])

  const signInWithGoogle = () => {
    setLoading(true)
    setError(undefined)
    try {
      if (firebase) {
        const googleProvider = new firebase.auth.GoogleAuthProvider()
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
          firebase.auth().signInWithPopup(googleProvider).then((user) => {
            processUser(user, true)
          }).catch((error) => {
            setLoading(false)
            setError(error.message)
          })
        })
      } else {
        setLoading(false)
        setError('Ooops! The service is not available for the moment. Please try again later.')
      }
    } catch (error) {
      setLoading(false)
      setError(error.message)
    }
  }

  const signUp = () => {
    setLoading(true)
    setError(undefined)
    try {
      if (firebase) {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
          firebase.auth().createUserWithEmailAndPassword(email, password).then((user) => {
            processUser(user, false)
          }).catch((error) => {
            setLoading(false)
            setError(error.message)
          })
        })
      } else {
        setLoading(false)
        setError('Ooops! The service is not available for the moment. Please try again later.')
      }
    } catch (error) {
      setLoading(false)
      setError(error.message)
    }
  }

  const signIn = () => {
    setLoading(true)
    setError(undefined)
    try {
      if (firebase) {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
          firebase.auth().signInWithEmailAndPassword(email, password).then((user) => {
            processUser(user, true)
          }).catch((error) => {
            setLoading(false)
            setError(error.message)
          })
        })
      } else {
        setLoading(false)
        setError('Ooops! The service is not available for the moment. Please try again later.')
      }
    } catch (error) {
      setLoading(false)
      setError(error.message)
    }
  }

  const processUser = (user, withProfile) => {
    if (user !== null) {
      if(user?.additionalUserInfo?.isNewUser && !withProfile){
           console.log("New  User");
      }
      if (withProfile) {
        setProfileWithFirebase().then((profile) => {
          setProfile(profile)
          setLoading(false)
          setActiveScreen(activeScreen + 1)
        })
      } else {
        setLoading(false)
        setActiveScreen(activeScreen + 1)
      }
    }
  }

  const sendPasswordReset = () => {
    firebase.auth().sendPasswordResetEmail(email).then(() => {
      setError("An email has been sent.")
    }).catch((error) => {
      setError(error.message)
    })
  }

  const setProfileWithFirebase = () => {
    return new Promise((resolve) => {
      if (firebase) {
        firebase
          .functions()
          .httpsCallable('getProfile')().then((result) => {
            if (result.data !== null) {
              if (result.data.profile !== null) {
                if (type === "wizard") {
                  let tempProfile = {
                    ...profile,
                    firstName: result.data.profile.firstName,
                    lastName: result.data.profile.lastName,
                    email: result.data.profile.email,
                    address: result.data.profile.address,
                    postcode: result.data.profile.postcode,
                    city: result.data.profile.city,
                    state: result.data.profile.state,
                    phone: result.data.profile.phone,
                    addressType: result.data.profile.addressType,
                    delivery: result.data.profile.delivery,
                    cartStatus: result.data.profile.cartStatus,
                    orderCount: result.data.profile.orderCount
                  }
                  handleDiscountBar(tempProfile);
                  resolve(tempProfile);
                } else {
                  if (type === "management") {
                    resolve(defaultProfile())
                  } else {
                    if ((type === "account") && (result.data.profile.cartStatus !== "notpaid"))
                      resolve(result.data.profile)
                    else {
                      firebase.auth().signOut().then(() => {
                        setError('You need to complete <a href="/getstarted" style="text-decoration: underline">Get Started</a> first.')
                        resolve(defaultProfile())
                      })
                    }
                  }
                }
              } else {
                if (type === "wizard")
                  resolve(profile)
                else {
                  firebase.auth().signOut().then(() => {
                    setError('You need to complete <a href="/getstarted" style="text-decoration: underline">Get Started</a> first.')
                    resolve(defaultProfile())
                  })
                }
              }
            } else {
              resolve(profile)
            }
          })
      } else {
        resolve({})
      }
    })
  }

  return (
    <Fragment>
      {!loading ?
        <div className="max-w-md w-full">
          {error === undefined ? null :
            <Warning text={error} />
          }
          <InputText label="Email" identifier="email" onChange={(event) => setEmail(event.target.value)} variable={email} maxLength="100" />
          <InputText label="Password" identifier="password" onChange={(event) => setPassword(event.target.value)} variable={password} maxLength="50" type="password" />
          <div className="mt-8">
            {type !== "wizard" ?
              <Fragment>
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-delidoor-light text-base font-medium text-black hover:bg-delidoor-dark focus:outline-none"
                  onClick={() => { signIn() }}
                >
                  Sign in
                </button>
                <div className="flex justify-start my-2">
                  <div className="mr-2">Forgot your password?</div>
                  <button
                    type="button"
                    className="underline"
                    onClick={() => { sendPasswordReset() }}
                  >
                    Password reset
                  </button>
                </div>
              </Fragment>
              :
              <Fragment>
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-delidoor-light text-base font-medium text-black hover:bg-delidoor-dark focus:outline-none"
                  onClick={() => { signUp() }}
                >
                  Sign up
                </button>
                {firebase.auth().currentUser !== null ? null :
                  <Fragment>
                    <div className="flex justify-start my-2">
                      <div className="mr-2">Already a customer?</div>
                      <button
                        type="button"
                        className="underline"
                        onClick={() => { signIn() }}
                      >
                        Sign in
                      </button>
                    </div>
                    <div className="flex justify-start my-2">
                      <div className="mr-2">Forgot your password?</div>
                      <button
                        type="button"
                        className="underline"
                        onClick={() => { sendPasswordReset() }}
                      >
                        Password reset
                      </button>
                    </div>
                  </Fragment>
                }
              </Fragment>
            }
          </div>
          <hr className="mt-8" />
          <div className="mt-8">
            <button className="flex items-center justify-center w-full max-w-xs p-2 border border-black rounded hover:shadow-lg" onClick={signInWithGoogle}>
              <div className="min-h-min">
                <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                  <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                    <path fill="#4285F4" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z" />
                    <path fill="#34A853" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z" />
                    <path fill="#FBBC05" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z" />
                    <path fill="#EA4335" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z" />
                  </g>
                </svg>
              </div>
              <div>Sign-in with Google</div>
            </button>
          </div>
        </div>
        :
        <Loading text="We are signing you in!" />
      }
    </Fragment>
  )
}