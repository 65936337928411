import React from "react"

export default function Loading({ text }) {
  const Spinner = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" style={{ margin: "auto", display: "block", shapeRendering: "auto" }} width="60px" height="60px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" r="32" strokeWidth="8" stroke="#a3ca81" strokeDasharray="50.26548245743669 50.26548245743669" fill="none" strokeLinecap="round">
          <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
        </circle>
      </svg>
    )
  }
  return (
    <div className={"flex flex-col justify-center items-center w-full pt-10 bg-white" + (text === undefined ? " h-full bg-opacity-50 transition-opacity" : "")}>
      {text === undefined ? null :
        <div className="text-xl text-center font-medium pb-5">
          {text}
        </div>
      }
      <div className="pt-5">
        <Spinner />
      </div>
    </div>
  )
}