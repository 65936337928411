export default function defaultProfile() {
    var nextDate = getFirstAvailableDate()
    let deliveryTime = "Tuesdays: 7:00am - 6:00pm"
    if (nextDate.getDay() === 3)
        deliveryTime = "Wednesdays: 7:00am - 6:00pm"
    else
        if (nextDate.getDay() === 5)
            deliveryTime = "Fridays: 7:00am - 6:00pm"
    return { household: "familyYoungKids", meals: "4", cadence: "weekly", state: "NSW", city: "", addressType: "Home", nextDate: nextDate.toISOString(), deliveryTime: deliveryTime, cart: [], exclude: [] , orderCount:0 }
}

export function getFirstAvailableDate() {
    var now = new Date()
    var currentHour = Number(now.toLocaleString('en-AU', { hour: '2-digit', hour12: false, timeZone: 'Australia/Sydney' }))
    var days = [2, 2, 3, 2, 5, 4, 3]
    if (currentHour >= 20)
        days = [3, 4, 3, 6, 5, 4, 3]
    var nextDate = now
    return new Date(nextDate.setDate(nextDate.getDate() + days[nextDate.getDay()]))
}