import React, { Fragment, useState, useEffect } from "react"
import firebase from "gatsby-plugin-firebase"
import Loading from "./loading"
import { Warning } from "./useInput"

export default function InvoicesManagement() {
  const [error, setError] = useState(undefined)
  const [invoices, setInvoices] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadNext, setLoadNext] = useState(false)
  const [nextPage, setNextPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const dateFormatOptions = { year: "2-digit", month: "numeric", day: "numeric" }
  const formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
  })

  useEffect(() => {
    let mounted = true

    const loadPage = () => {
      if ((nextPage === 0 && totalPages === 0) || (nextPage < totalPages)) {
        setLoading(true)
        const requestData = {
          cursor: nextPage
        }
        firebase
          .functions()
          .httpsCallable('listInvoices')(requestData).then((result) => {
            if (mounted) {
              if (result.data.invoices !== undefined) {
                const mergedInvoices = invoices.concat(result.data.invoices)
                if (result.data.nextPage > nextPage)
                  setNextPage(result.data.nextPage)
                else
                  setNextPage(result.data.totalPages)
                setTotalPages(result.data.totalPages)
                setInvoices(mergedInvoices)
              } else {
                setError(result.data.message)
              }
              setLoading(false)
            }
          })
      }
    }

    loadPage()
    return () => { mounted = false };
  }, [loadNext])



  const refundAction = (invoiceId, amount) => {
    var answer = window.confirm(`Do you want to refund ${formatter.format(amount)}?`);
    if (answer) {
      setLoading(true)
      const requestData = {
        invoiceId: invoiceId
      }
      firebase
        .functions()
        .httpsCallable('refundInvoice')(requestData).then((result) => {
          if (result.data.id !== undefined) {
            const modifiedInvoices = invoices
            const objIndex = invoices.findIndex(f => f.id === invoiceId)
            modifiedInvoices[objIndex].temporary = "MODIFIED"
            modifiedInvoices[objIndex].status = result.data.status
            setInvoices(modifiedInvoices)
          } else {
            setError(result.data.message)
          }
          setLoading(false)
        });
    }
  }

  const refundPartialAction = (invoiceId, amount) => {
    var answer = window.prompt("Please enter amount to refund");

    if (answer !== null) {
      if (parseFloat(answer) > parseFloat(amount)) {
        alert("Refund amount cannot be greater than invoice amount.");
      } else {
        setLoading(true)
        const requestData = {
          invoiceId: invoiceId,
          partialRefundAmount: answer
        }
        firebase
          .functions()
          .httpsCallable('refundPartialInvoice')(requestData).then((result) => {
            if (result.data.id !== undefined) {
              const modifiedInvoices = invoices
              const objIndex = invoices.findIndex(f => f.id === invoiceId)
              modifiedInvoices[objIndex].temporary = "MODIFIED"
              modifiedInvoices[objIndex].status = result.data.status
              setInvoices(modifiedInvoices)
            } else {
              setError(result.data.message)
            }
            setLoading(false)
          });
      }
    }
  }

  const retryAction = (invoiceId, amount, currentPaymentMethodToken) => {
    var answer = window.confirm(`Do you want to retry ${formatter.format(amount)}?`);
    if (answer) {
      setLoading(true)
      const requestData = {
        invoiceId: invoiceId,
        currentPaymentMethodToken: currentPaymentMethodToken
      }
      firebase
        .functions()
        .httpsCallable('retryInvoice')(requestData).then((result) => {
          if (result.data.id !== undefined) {
            const modifiedInvoices = invoices
            const objIndex = invoices.findIndex(f => f.id === invoiceId)
            modifiedInvoices[objIndex].temporary = "RETRIED"
            modifiedInvoices[objIndex].status = result.data.status
            setInvoices(modifiedInvoices)
          } else {
            setError(result.data.message)
          }
          setLoading(false)
        });
    }
  }

  return (
    <div className="w-full max-w-xl ">
      {error === undefined ? null :
        <Warning text={error} />
      }
      {invoices.length === 0 ? null :
        <Fragment>
          <div className="text-xl font-medium my-4">Délidoor invoices</div>
          <table className="table-auto w-full text-xs border-b">
            <thead className="hidden sm:table-header-group">
              <tr>
                <th className="text-left py-2 pr-2">Invoice date</th>
                <th className="text-left p-2">Email</th>
                <th className="text-right p-2">Amount (AUD)</th>
                <th className="text-right p-2">Status</th>
                <th className="text-right p-2">Card expiry</th>
                <th>{null}</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice => {
                return (
                  <tr className="border-t" key={invoice.id}>
                    <td className="text-left py-2 pr-2 hidden sm:table-cell">{new Date(invoice.date).toLocaleDateString("en-AU", dateFormatOptions)}</td>
                    <td className="text-left py-2 break-all table-cell sm:hidden"><div><span className="font-semibold">Invoice date: </span>{new Date(invoice.date).toLocaleDateString("en-AU", dateFormatOptions)}</div><div><span className="font-semibold">Email: </span>{invoice.email}</div><div><span className="font-semibold">Amount (AUD): </span>{formatter.format(invoice.amount)}</div><div><span className="font-semibold">Status: </span>{invoice.status}</div><div><span className="font-semibold">Card expiry: </span>{invoice.cardExpiry}</div></td>
                    <td className="text-left p-2 break-all hidden sm:table-cell">{invoice.email}</td>
                    <td className="text-right p-2 hidden sm:table-cell">{formatter.format(invoice.amount)}</td>
                    <td className="text-right p-2 break-all hidden sm:table-cell">{invoice.status}</td>
                    <td className="text-right p-2 break-all hidden sm:table-cell">{invoice.cardExpiry}</td>
                    <td className="text-right py-2 pl-2">
                      <button
                        type="button"
                        className={"inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 my-2 focus:outline-none text-black" + (invoice.status !== "PAID" || invoice.temporary !== "ORIGINAL" ? " bg-gray-300" : " bg-red-200 hover:bg-red-400")}
                        onClick={() => { refundAction(invoice.id, invoice.amount) }}
                        disabled={invoice.status !== "PAID" || invoice.temporary !== "ORIGINAL"}
                      >
                        Refund
                      </button>
                      <button
                        type="button"
                        className={"inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 my-2 focus:outline-none text-black" + (invoice.status !== "PAID" || invoice.temporary !== "ORIGINAL" ? " bg-gray-300" : " bg-red-200 hover:bg-red-400")}
                        onClick={() => { refundPartialAction(invoice.id, invoice.amount) }}
                        disabled={invoice.status !== "PAID" || invoice.temporary !== "ORIGINAL"}
                      >
                        Partial Refund
                      </button>
                      <button
                        type="button"
                        className={"inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 my-2 ml-2 focus:outline-none text-black" + (invoice.status !== "PAST_DUE" || invoice.currentPaymentMethodToken === undefined ? " bg-gray-300" : " bg-red-200 hover:bg-red-400")}
                        onClick={() => { retryAction(invoice.id, invoice.amount, invoice.currentPaymentMethodToken) }}
                        disabled={invoice.status !== "PAST_DUE" || invoice.currentPaymentMethodToken === undefined}
                      >
                        Retry
                      </button>
                    </td>
                  </tr>
                )
              }))}
            </tbody>
          </table>
        </Fragment>
      }
      {!loading ?
        <button
          type="button"
          className={"w-32 inline-flex text-base justify-center rounded-md border border-transparent shadow-sm px-4 py-2 mt-4 font-medium text-black focus:outline-none" + (nextPage >= totalPages ? " bg-gray-300" : " bg-delidoor-light hover:bg-delidoor-dark")}
          onClick={() => { if (nextPage < totalPages) setLoadNext(!loadNext) }}
        >
          Load more
        </button>
        :
        <Loading text="Loading invoices!" />
      }
    </div>
  )
}