import React, { useState, Fragment } from "react"
import SignUp from "../components/signup"
import InvoicesManagement from "../components/invoicesManagement"
import LayoutManagement from "../components/layoutManagement"
import firebase from "gatsby-plugin-firebase"
import Meta from "../components/meta"
import defaultProfile from "../components/defaultProfile"

export default function ManagementPage() {
  const [profile, setProfile] = useState(() => { return defaultProfile() })
  const [activeScreen, setActiveScreenState] = useState(0)

  const setActiveScreenFromSignup = (screen) => {
    if (firebase.auth().currentUser !== null)
      setActiveScreenState(1)
    else
      setActiveScreenState(0)
    window["scrollTo"]({ top: 0, behavior: "smooth" })
  }

  return (
    <Fragment>
      <Meta title="Management" />
      <LayoutManagement>
        <Fragment>
          {(() => {
            switch (activeScreen) {
              case 1: return (<InvoicesManagement />)
              default: return (<SignUp activeScreen={activeScreen} setActiveScreen={setActiveScreenFromSignup} profile={profile} setProfile={setProfile} type="management" />)
            }
          })()}
        </Fragment>
      </LayoutManagement>
    </Fragment>
  )
}