import React from "react";

export function Warning({ text }) {
    const Sign = () => {
        return (
            <div className="min-w-min">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="pr-2">
                    <path className="fill-current text-red-700" d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 5h2v10h-2v-10zm1 14.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z" />
                </svg>
            </div>
        )
    }
    if (text)
        return (
            <div className="flex flex-nowrap items-center">
                <Sign />
                <div className="text-red-700 w-full break-words" dangerouslySetInnerHTML={{ __html: text }} />
            </div>
        )
    else
        return (<Sign />)
}

export function WarningInput({ variable, validate, label }) {
    if (validate)
        if (variable === undefined)
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="pr-2">
                    <title>{label + " must not be blank."}</title>
                    <path className="fill-current text-red-700" d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 5h2v10h-2v-10zm1 14.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z" />
                </svg>
            )
    return (null)
}

export function InputText({ label, identifier, variable, validate, maxLength = "100", type = "text", readOnly = false, onChange, onBlur }) {
    return (
        <div className="md:flex md:flex-wrap md:items-center md:justify-between my-4">
            <label
                className="my-2 flex justify-left md:justify-between text-center"
                htmlFor={identifier}
            >
                <WarningInput variable={variable} validate={validate} label={label} />
                {label}
            </label>
            {readOnly ?
                <input className={"border border-black w-full md:max-w-xs p-2 rounded-md block focus:outline-none" + (readOnly ? "" : " hover:shadow-lg")} readOnly={readOnly} value={variable} maxLength={maxLength} type={type} name={identifier} />
                :
                onChange !== undefined ?
                    <input className={"border border-black w-full md:max-w-xs p-2 rounded-md block focus:outline-none" + (readOnly ? "" : " hover:shadow-lg")} onChange={onChange} readOnly={readOnly} defaultValue={variable} maxLength={maxLength} type={type} name={identifier} />
                    :
                    <input className={"border border-black w-full md:max-w-xs p-2 rounded-md block focus:outline-none" + (readOnly ? "" : " hover:shadow-lg")} onBlur={onBlur} readOnly={readOnly} defaultValue={variable} maxLength={maxLength} type={type} name={identifier} />
            }
        </div>
    )
}

export function InputTextArea({ label, identifier, variable, validate, maxLength = "100", type = "text", readOnly = false, onChange }) {
    return (
        <div className="md:flex md:flex-wrap md:items-start md:justify-between my-4">
            <label
                className="my-2 flex justify-left md:justify-between text-center"
                htmlFor={identifier}
            >
                <WarningInput variable={variable} validate={validate} label={label} />
                {label}
            </label>
            <textarea className={"border border-black w-full md:max-w-xs p-2 rounded-md block focus:outline-none" + (readOnly ? "" : " hover:shadow-lg")} onChange={onChange} readOnly={readOnly} defaultValue={variable} maxLength={maxLength} type={type} name={identifier} />
        </div>
    )
}


export function InputSelect({ label, identifier, variable, validate, onChange, values, readOnly }) {
    return (
        <div className="md:flex md:flex-wrap md:items-center md:justify-between my-4">
            <label
                className="my-2 flex justify-left md:justify-between text-center"
                htmlFor={identifier}
            >
                <WarningInput variable={variable} validate={validate} label={label} />
                {label}
            </label>
            {readOnly ?
                <input className="border border-black w-full md:max-w-xs p-2 rounded-md block focus:outline-none" readOnly={true} value={variable} />
                :
                <select
                    className="border border-black w-full md:max-w-xs px-1 py-2 rounded-md block hover:shadow-lg focus:outline-none"
                    name={identifier}
                    onChange={onChange}
                    defaultValue={variable}
                >
                    {values.map((value) => {
                        return (
                            <option key={value} value={value}>{value}</option>
                        )
                    })}
                </select>
            }
        </div>
    )
}

export function InputSelectInLine({ identifier, variable, onChange, values, readOnly }) {
    return (
        <div className="md:flex md:flex-wrap md:items-center md:justify-between">
            {readOnly ?
                <input className="border border-black w-16 p-2 rounded-md block focus:outline-none" readOnly={true} value={variable} />
                :
                <select
                    className="border border-black w-16 px-1 py-2 rounded-md block hover:shadow-lg focus:outline-none"
                    name={identifier}
                    onChange={onChange}
                    defaultValue={variable}
                >
                    {values.map((value) => {
                        return (
                            <option key={value} value={value}>{value}</option>
                        )
                    })}
                </select>
            }
        </div>
    )
}